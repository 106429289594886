<template>
  <!-- <Code to place Help dialog in the center of the screen > -->
  <v-dialog persistent v-model="topic" max-width="900">
    <v-card>
      <v-card-title primary-title>
        <v-flex justify-start>
          <v-btn
            @click="openTopicDetails(topic.topicParentName)"
            text
            v-if="topic.topicParentName"
            color="blue darken-1"
            style="text-transform:none !important;"
          >
            <v-icon class="mdi-rotate-270">mdi-apple-keyboard-control</v-icon>
                {{ $t("Back") }}
          </v-btn>
        </v-flex>
        <v-flex justify-center style="text-align:center;">
          <span>{{ $t(topic.topicName) }}</span>
        </v-flex>
        <v-flex justify-end>
          <v-btn
            color="blue darken-1"
            text
            @click="closeHelp"
            style="float: right; text-transform:none !important;"
          >
            {{ $t("Done") }}
          </v-btn>
        </v-flex>
      </v-card-title>
      <hr>
      <div v-if="topic.topicName === 'Help'">
       <v-card-title class="headline">LatLonGo</v-card-title>
        <v-card-text>
          <div >
            <b style="color: black;">{{ $t("Introduction") }}</b>
          </div>
          <div style="color: black;">LatLonGO web app lets the administrators work with GIS data on a map.</div>
        </v-card-text>
      </div>
      <v-card-text>
        <div
          style="padding: 2%; text-align: justify;"
          v-if="topic.topicDescription"
        >
           <v-icon class="inlineWithText"
           >{{ topic.topicSymbol }}</v-icon>
               
          <span style="color: black;" class="headline">{{ $t(topic.topicName) }}</span>
          <div></div>
          <span>{{ $t(topic.topicDescription) }} </span>
            <v-icon v-if="topic.topicCameraSymbol"
           >{{ topic.topicCameraSymbol }}</v-icon>
          <span v-if="topic.topicHyperlink">
            <a :href="topic.topicHyperlink"> Mapcodes </a>
          </span >
          <span v-if="topic.topicDescription1">
            {{ $t(topic.topicDescription1) }}
          </span>
          <div v-if="topic.routingCarName">
            <b>
                {{ $t(topic.routingCarName)}}
            </b>
          </div>
          <div v-if="topic.routingCarDesc">
                {{ $t(topic.routingCarDesc)}}
          </div>
           <div v-if="topic.routingTruckName">
            <b>
                {{ $t(topic.routingTruckName)}}
            </b>
          </div>
          <div v-if="topic.routingTruckDesc">
                {{ $t(topic.routingTruckDesc)}}
          </div>
           <div v-if="topic.routingPublicName">
            <b>
                {{ $t(topic.routingPublicName)}}
            </b>
          </div>
          <div v-if="topic.routingPublicDesc">
                {{ $t(topic.routingPublicDesc)}}
          </div>
           <div v-if="topic.routingPedestrainName">
            <b>
                {{ $t(topic.routingPedestrainName)}}
            </b>
          </div>
          <div v-if="topic.routingPedestrainDesc">
                {{ $t(topic.routingPedestrainDesc)}}
          </div>
              <v-icon v-if="topic.topicMicrophoneSymbol"
           >{{ topic.topicMicrophoneSymbol }}</v-icon>
           <span v-if="topic.topicDescription2">
            {{ $t(topic.topicDescription2) }}
          </span>
          <div v-if="topic.topicScreenShot">
             <v-img  :src="require(`@/assets/help/screenshots/${topic.topicScreenShot}.png`)" 
              />
          </div>
          <div v-if="topic.topicAfterScreenshotDescription">
             {{ $t(topic.topicAfterScreenshotDescription)}}
             </div>
             <div v-if="topic.topicAfterScreenshotBoldDescription">
               <b>
                {{ $t(topic.topicAfterScreenshotBoldDescription)}}
               </b>
             </div>
                <div v-if="topic.topicScreenShot1">
             <v-img  :src="require(`@/assets/help/screenshots/${topic.topicScreenShot1}.png`)" 
              />
                </div>
                    <div v-if="topic.topicDescription3">
             {{ $t(topic.topicDescription3)}}
             </div>

        </div>
        <v-list dense>
          <template v-for="topic in topicDetails">
            <v-list-item
              :key="topic.topicName"
              @click="openTopicDetails(topic.topicName)"
            >
              <v-list-item-action>
                <v-icon>{{ topic.topicSymbol }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(topic.topicName) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import jsonData from "@/util/help";

export default {
  name: "help",
  data() {
    return {
      topic: {
        topicName: "topic-name",
        topicParentName: "topic-parent-name",
        topicDescription: "topic-description",
        topicSymbol: "mdi-apple-keyboard-control"
      },
      topicDetails: []
    };
  },
  created() {
    console.log("...Help panel created.");
    this.openTopicDetails("Help");
  },

  methods: {
    closeHelp() {
      this.$store.commit("changeIsHelpDialogState", false);
    },
    openTopicDetails(topicName) {
      this.topic = jsonData.find(element => element.topicName === topicName);
      this.topicDetails = jsonData.filter(
        element => element.topicParentName === this.topic.topicName
      );
    }
  }
};
</script>
<style>
.v-dialog__content {
  align-items: initial;
}
.v-dialog {
  box-shadow: none;
}
.inlineWithText {
  padding-bottom: 10px;
  padding-right: 4px;
}
</style>
